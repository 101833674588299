var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Warrant terms',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Warrant Terms","placeholder":"Select warrant terms","items":_vm.warrantTerms,"item-text":"name","item-value":"id","error-messages":errors},on:{"change":_vm.updateWarrants},model:{value:(_vm.formData.warrant_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "warrant_terms_id", $$v)},expression:"formData.warrant_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Select financing round","items":_vm.financingRounds,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.formData.financing_round_id),callback:function ($$v) {_vm.$set(_vm.formData, "financing_round_id", $$v)},expression:"formData.financing_round_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Granted warrants',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted Warrants","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 0 }},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Exercise price per share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Exercise Price Per Share","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.formData.strike_price),callback:function ($$v) {_vm.$set(_vm.formData, "strike_price", $$v)},expression:"formData.strike_price"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Grant date', mandatory: true},"clearable":""},model:{value:(_vm.grantDate),callback:function ($$v) {_vm.grantDate=$$v},expression:"grantDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Expiration date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Expiration Date', mandatory: true},"clearable":""},model:{value:(_vm.expiresOn),callback:function ($$v) {_vm.expiresOn=$$v},expression:"expiresOn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.warrantId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS,"is-submit":_vm.isSubmit},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }