<template>
  <v-form ref="form">
    <holders-selection-form
      v-model="holder"
      :title="'Select Holder'"
      :holders-list="holdersList"
      @update="updateHolderId"
    />
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Warrant terms'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="formData.warrant_terms_id"
            label="Warrant Terms"
            placeholder="Select warrant terms"
            :items="warrantTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            @change="updateWarrants"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing round'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="formData.financing_round_id"
            label="Financing Round"
            placeholder="Select financing round"
            :items="financingRounds"
            item-text="name"
            item-value="id"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Granted warrants'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="formData.number"
            label="Granted Warrants"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 0 }"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Exercise price per share'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="formData.strike_price"
            label="Exercise Price Per Share"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Grant date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="grantDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :input-templ="{label: 'Grant date', mandatory: true}"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Expiration date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="expiresOn"
            :mask="'##/##/####'"
            :error-messages="errors"
            :input-templ="{label: 'Expiration Date', mandatory: true}"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovedOn"
            :mask="'##/##/####'"
            :error-messages="errors"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Stock registration name'"
          :rules="{ required: true }"
        >
          <common-input-text-field
            v-model="stockRegistrationName"
            label="Stock Registration Name"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <notes-and-documents-form
          :id="warrantId"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS"
          :is-submit="isSubmit"
          @navToList="$emit('navToList', true)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from './HoldersSelectionForm'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'

  export default {
    name: 'WarrantsForm',

    components: {
      HoldersSelectionForm,
      NotesAndDocumentsForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
      holder: {
        type: Object,
        required: true,
      },
      warrantTerms: {
        type: Array,
        required: true,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      holdersList: {
        type: Array,
        required: true,
      },
      warrantId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
      },
    },

    data: () => ({
      notesData: {},
      grantDate: '',
      expiresOn: '',
      boardApprovedOn: '',
      stockRegistrationName: '',
    }),

    computed: {
      formData: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      selectedWarrantTerms: {
        get () {
          return this.formData.warrant_terms_id || ''
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.grantDate = val.granted_on ? moment(val.granted_on).format('MM/DD/YYYY') : ''
            this.expiresOn = val.expires_on ? moment(val.expires_on).format('MM/DD/YYYY') : ''
            this.boardApprovedOn = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      grantDate: {
        handler (val) {
          if (val) {
            this.formData.granted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      expiresOn: {
        handler (val) {
          if (val) {
            this.formData.expires_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovedOn: {
        handler (val) {
          if (val) {
            this.formData.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      selectedWarrantTerms: {
        handler (val) {
          if (val) {
            const warrantTerm = this.warrantTerms.find(term => term.id === val)
            this.stockRegistrationName = `${this.CONSTS.SECURITIES_PREFIX.WARRANT}-`
            if (warrantTerm.board_approved_on) {
              this.boardApprovedOn = moment(warrantTerm.board_approved_on).format('MM/DD/YYYY')
            } else {
              this.boardApprovedOn = ''
            }
          }
        },
        immediate: true,
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        this.$emit('updateHolder', val)
      },
      updateWarrants () {
        const selectedWarrant = this.warrantTerms.find((w) => { return w.id === this.formData.warrant_terms_id })
        this.formData.share_terms_id = selectedWarrant.share_terms_id
      },
    },
  }
</script>
